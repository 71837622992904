import React, { useEffect, useState } from 'react';
import axios from 'axios';

function FundingAR() {
    const [arbitrageData, setArbitrageData] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL + '/api/funding-arbitrage')
            .then(response => {
                // Ensure 'arbitrage' is parsed as a number
                const data = response.data.map(row => ({
                    ...row,
                    arbitrage: parseFloat(row.arbitrage),
                }));
                setArbitrageData(data);
            })
            .catch(error => {
                console.error('Error fetching funding rate arbitrage opportunities:', error);
            });
    }, []);

    return (
        <div>
            <h2>Top 5 Funding Rate Arbitrage Opportunities</h2>
            <table>
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>First Source</th>
                        <th>First Funding Rate</th>
                        <th>Second Source</th>
                        <th>Second Funding Rate</th>
                        <th>Arbitrage (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {arbitrageData.map((row, index) => (
                        <tr key={index}>
                            <td>{row.symbol}</td>
                            <td>{row.firstSource}</td>
                            <td>{parseFloat(row.firstFundingRate)}</td>
                            <td>{row.secondSource}</td>
                            <td>{parseFloat(row.secondFundingRate)}</td>
                            <td><strong>{row.arbitrage}%</strong></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default FundingAR;
