import React from 'react';
import '../styles/AboutUs.css'; // Ensure the correct path to the CSS file

function AboutUs() {
  return (
    <div className="about-us-container">
      <h1>About Us</h1>
      <p>
        We are a dedicated team of developers passionate about providing our members with a trading advantage through live data. 
        Our mission is to empower traders with accurate and real-time information that helps them make informed decisions in the fast-paced world of cryptocurrency trading.
      </p>
      
      <div className="contact-section">
        <h2>Contact Us</h2>
        <p>If you have any questions or want to reach out, connect with us on Telegram:</p>
        <img src="/telegram-qr.png" alt="Telegram QR Code" className="qr-code" />
      </div>
    </div>
  );
}

export default AboutUs;
