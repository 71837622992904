import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../styles/FundingRates.css';

function FundingRates() {
  const [highestRates, setHighestRates] = useState([]);
  const [lowestRates, setLowestRates] = useState([]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_URL + '/api/highest-funding-rates')
    .then(response => {
        setHighestRates(response.data);
      })
      .catch(error => {
        console.error('Error fetching highest funding rates:', error);
      });

    axios.get(process.env.REACT_APP_URL + '/api/lowest-funding-rates')
      .then(response => {
        setLowestRates(response.data);
      })
      .catch(error => {
        console.error('Error fetching lowest funding rates:', error);
      });
  }, []);

  const getClassForRate = (rate) => {
    return rate > 0 ? 'high-rate' : 'low-rate';
  };

  return (
    <div>
      <h2>Highest Funding Rates</h2>
      <table>
        <thead>
          <tr>
            <th>Exchange Name</th>
            <th>Symbol</th>
            <th>Funding Rate</th>

          </tr>
        </thead>
        <tbody>
          {highestRates.map((rate, index) => (
            <tr key={index}>
              <td>{rate.exchange_name}</td>
              <td>{rate.symbol}</td>
              <td className={getClassForRate(rate.funding_rate)}>{rate.funding_rate}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>Lowest Funding Rates</h2>
      <table>
        <thead>
          <tr>
            <th>Exchange Name</th>
            <th>Symbol</th>
            <th>Funding Rate</th>

          </tr>
        </thead>
        <tbody>
          {lowestRates.map((rate, index) => (
            <tr key={index}>
              <td>{rate.exchange_name}</td>
              <td>{rate.symbol}</td>
              <td className={getClassForRate(rate.funding_rate)}>{rate.funding_rate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default FundingRates;
