import React from 'react';
import '../styles/Home.css';

function Home() {
  return (
    <div className="home-container">
      <div className="hero-section">
        <div className="hero-content">
          <h1>Welcome Arbirate.io</h1>
          <p>Your gateway to profitable arbitrage trading with real-time, valuable data.</p>
          <a href="/funding-rates" className="explore-button">Explore Now</a>
        </div>
      </div>
    </div>
  );
}

export default Home;
