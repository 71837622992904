import React from 'react';
import '../styles/PrivacyPolicy.css'; // Ensure the correct path to the CSS file

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1>Privacy Policy</h1>
      <p>Welcome to our Privacy Policy page. This document outlines how we handle the personal information we collect and receive when you use our services.</p>

      <h2>Data Collection & Usage</h2>
      <p>We do not collect personal data unless you voluntarily provide it to us, such as when you sign up for services or subscribe to updates. The information we collect is used to enhance your experience, provide customer support, or communicate with you.</p>

      <h2>Cookies & Tracking</h2>
      <p>Our website may use cookies to improve the user experience. You can set your browser to refuse cookies or alert you when they are being sent. Disabling cookies may affect how the website functions.</p>

      <h2>Legal Protection & Changes</h2>
      <p>We are committed to protecting your privacy. However, we may disclose information when required by law or to protect our rights. This policy may be updated periodically, and we encourage you to review it to stay informed about how we protect your information.</p>

      <p>If you have any questions or concerns about this Privacy Policy, please contact us.</p>
    </div>
  );
};

export default PrivacyPolicy;
