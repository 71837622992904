import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Arbitrage() {
    const [arbitrageData, setArbitrageData] = useState([]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL + '/api/top-arbitrage')
            .then(response => {
                setArbitrageData(response.data);
            })
            .catch(error => {
                console.error('Error fetching top arbitrage opportunities:', error);
            });
    }, []);

    return (
        <div>
            <h2>Top 5 Arbitrage Opportunities</h2>
            <table>
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Max Exchange</th>
                        <th>Price</th>
                        <th>Min Exchange</th>
                        <th>Price</th>
                        <th>Difference (%)</th>
                    </tr>
                </thead>
                <tbody>
                    {arbitrageData.map((row, index) => (
                        <tr key={index}>
                            <td>{row.symbol}</td>
                            <td>{row.FirstSource}</td>
                            <td><strong>{parseFloat(row.FirstPrice)}</strong></td>
                            <td>{row.SecondSource}</td>
                            <td><strong>{parseFloat(row.SecondPrice)}</strong></td>
                            <td>{parseFloat(row.Arb).toFixed(2)}%</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Arbitrage;
