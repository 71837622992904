import React, { useState } from 'react';
import '../styles/Exchanges.css';

const Exchanges = () => {
    const [activeTab, setActiveTab] = useState('Spot'); // default to Spot view

    const spotFees = [
        { name: 'Binance', makerFee: '0.10%', takerFee: '0.10%' },
        { name: 'Coinbase Exchange', makerFee: '0.50%', takerFee: '0.50%' },
        { name: 'OKX', makerFee: '0.08%', takerFee: '0.10%' },
        { name: 'Bybit', makerFee: '0.10%', takerFee: '0.15%' },
        { name: 'Kraken', makerFee: '0.16%', takerFee: '0.26%' },
        { name: 'KuCoin', makerFee: '0.10%', takerFee: '0.10%' },
        { name: 'Bitfinex', makerFee: '0.10%', takerFee: '0.20%' },
        // Add more exchanges here...
    ];

    const derivativesFees = [
        { name: 'Binance', makerFee: '0.02%', takerFee: '0.04%' },
        { name: 'Bybit', makerFee: '0.02%', takerFee: '0.055%' },
        { name: 'OKX', makerFee: '0.02%', takerFee: '0.05%' },
        { name: 'Bitget', makerFee: '0.02%', takerFee: '0.06%' },
        { name: 'DigiFinex', makerFee: '0.03%', takerFee: '0.05%' },
        { name: 'Deribit', makerFee: '0%', takerFee: '0.05%' },
        // Add more exchanges here...
    ];

    return (
        <div className="exchanges-container">
            <div className="toggle-buttons">
                <button 
                    className={activeTab === 'Spot' ? 'active' : ''} 
                    onClick={() => setActiveTab('Spot')}
                >
                    Spot
                </button>
                <button 
                    className={activeTab === 'Derivatives' ? 'active' : ''} 
                    onClick={() => setActiveTab('Derivatives')}
                >
                    Derivatives
                </button>
            </div>

            <table className="exchanges-table">
                <thead>
                    <tr>
                        <th>Exchange</th>
                        <th>Maker Fee</th>
                        <th>Taker Fee</th>
                    </tr>
                </thead>
                <tbody>
                    {(activeTab === 'Spot' ? spotFees : derivativesFees).map((exchange, index) => (
                        <tr key={index}>
                            <td>{exchange.name}</td>
                            <td>{exchange.makerFee}</td>
                            <td>{exchange.takerFee}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Exchanges;
