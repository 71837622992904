import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Articles.css';

function Articles() {
  return (
    <div className="articles-page">
      <main className="articles-content">
        <h2>Articles</h2>
        <div className="article-preview">
          <Link to="/articles/article1">
            <div className="article-details">
              <h3>Funding Rates</h3>
              <p>Understanding Crypto Funding Rates</p>
            </div>
          </Link>
        </div>
        <div className="article-preview">
          <Link to="/articles/article2">
            <div className="article-details">
              <h3>Crypto Arbitrage</h3>
              <p>Understanding Simple Arbitrage in Cryptocurrency Trading</p>
            </div>
          </Link>
        </div>
        {/* Add more articles similarly */}
      </main>
    </div>
  );
}

export default Articles;
