import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import './styles/App.css';
import FundingRates from './pages/FundingRates';
import Home from './pages/Home';
import Arbitrage from './pages/Arbitrage';
import FundingAR from './pages/FundingAr';
import Exchanges from './pages/Exchanges';
import Articles from './pages/Articles';
import Article1 from './pages/articles/article1';
import Article2 from './pages/articles/article2';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AboutUs from './pages/AboutUs';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav>
            <ul>
              <li>
                <NavLink to="/" className={({ isActive }) => isActive ? 'active' : ''}>Home</NavLink>
              </li>
              <li>
                <NavLink to="/funding-rates" className={({ isActive }) => isActive ? 'active' : ''}>Funding Rates</NavLink>
              </li>
              <li>
                <NavLink to="/arbitrage" className={({ isActive }) => isActive ? 'active' : ''}>Coin Arbitrage</NavLink>
              </li>
              <li>
                <NavLink to="/funding-arbitrage" className={({ isActive }) => isActive ? 'active' : ''}>Funding Rate Arbitrage</NavLink>
              </li>
              <li>
                <NavLink to="/exchanges" className={({ isActive }) => isActive ? 'active' : ''}>Exchanges</NavLink>
              </li>
              <li>
                <NavLink to="/articles" className={({ isActive }) => isActive ? 'active' : ''}>Articles</NavLink>
              </li>
              <li>
                <NavLink to="/about-us" className={({ isActive }) => isActive ? 'active' : ''}>About Us</NavLink>
              </li>
            </ul>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/funding-rates" element={<FundingRates />} />
          <Route path="/arbitrage" element={<Arbitrage />} />
          <Route path="/funding-arbitrage" element={<FundingAR />} />
          <Route path="/exchanges" element={<Exchanges />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/articles/article1" element={<Article1 />} />
          <Route path="/articles/article2" element={<Article2 />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
